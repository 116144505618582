import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, ActionSheetController, IonContent, NavController, ModalController, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { UserService } from 'src/app/services/user/user.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { SelectAddressPage } from '../select-address/select-address.page';
import { AutoConfirmPaymentPage } from '../auto-confirm-payment/auto-confirm-payment.page';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { CouponsListPage } from '../pages/coupons-list/coupons-list.page';
import { MultiRegionService } from '../services/multi-region/multi-region.service';
import { encodeURL } from '../services/parseURL';
import { CartService } from '../services/cart/cart.service';
import { OrderService } from '../services/order/order.service';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { DeliverySettingsService } from '../services/delivery-settings/delivery-settings.service';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';
export class DeliveryScheduleModalPage {
    constructor(events, router, activatedRoute, alertController, loadingController, storage, actionSheetController, modalController, navCtrl, labelService, configService, userService, sharedService, toastController, regionService, cartService, orderService, adminSettingsService, deliverySettingsService, multiCountriesService) {
        this.events = events;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.storage = storage;
        this.actionSheetController = actionSheetController;
        this.modalController = modalController;
        this.navCtrl = navCtrl;
        this.labelService = labelService;
        this.configService = configService;
        this.userService = userService;
        this.sharedService = sharedService;
        this.toastController = toastController;
        this.regionService = regionService;
        this.cartService = cartService;
        this.orderService = orderService;
        this.adminSettingsService = adminSettingsService;
        this.deliverySettingsService = deliverySettingsService;
        this.multiCountriesService = multiCountriesService;
        this.products = [];
        this.showCommentBoxAndImage = false;
        this.listOfCommentImages = {};
        this.placeholderMsgInCommentBox = '';
        this.defaultDeliveryAmt = 0;
        this.freeDeliveryAmt = 0;
        this.minOrderAmount = 0;
        this.productsPrice = 0;
        this.showLoader = true;
        this.couponCode = '';
        this.couponDiscount = 0;
        this.defaultGst = 0;
        this.couponApplied = false;
        this.couponId = '';
        this.timeSchedules = [];
        this.scheduledDates = [];
        this.gstAmount = 0;
        this.showPriceInfoLoader = true;
        this.totalMrp = 0;
        this.discountOnMrp = 0;
        this.deliveryGstObj = {};
        this.paymentDetails = {};
        this.addressType = 'shipping';
        this.accountType = 'personal';
        this.customerGstNo = '';
        this.storeAddress = {};
        this.isStorePickup = false;
        this.storePickupCharges = 0;
        this.deliveryType = 'delivery';
        this.isGstApplicable = true;
        this.isDeliveryScheduleMandatory = false;
        this.minHrsReq = 0;
        this.allowSameDayDelivery = false;
        this.minPincodeAmnt = 0;
        this.SHARED_LABELS = {};
        this.ORDER_SUMMARY_LABELS = {};
        this.offerMsg = '';
        this.needToDisable = false;
        this.isNoSubscriptionOrder = false;
        this.manageSlots = [];
        this.membershipDiscount = 0;
        this.isOnlyMembership = false;
        this.uploadDocSettings = {
            isActive: false
        };
        this.uploadedDoc = {
            name: '',
            uploads: []
        };
        this.orderUploadedDocImgsLimit = 5;
        this.storeDelivery = {
            estimatedTime: '',
            isActive: true
        };
        this.isKmDeliveryExist = false;
        this.isWeightDeliveryExist = false;
        this.estimatedDeliveryTime = '';
        this.instantDelivery = {
            isActive: false,
            time: null,
            isAvailable: false
        };
        this.isCodAvailableForCoupon = true;
        this.regionNotDeliverable = false;
        this.issueInGettingLocation = false;
        this.multiRegion = false;
        this.regionType = 'pincodes';
        this.freeProductsLimit = [];
        this.freeProductsAdded = [];
        this.customDeliveryOptions = [];
        this.extraChargeOnOrder = {
            name: '',
            charge: 0
        };
        this.customField = {
            active: false,
            name: '',
            value: ''
        };
        this.totalAddonsPrice = 0;
        let cashfreeOrderId = this.activatedRoute.snapshot.queryParamMap.get('cf_id');
        if (cashfreeOrderId && cashfreeOrderId.length > 0) {
            this.orderService.getOrderDetailsCashfree(cashfreeOrderId);
        }
    }
    ngOnInit() {
    }
    getStickyHeight(height) {
        this.stickyHeight = height;
        console.log('sticky height', this.stickyHeight);
    }
    getScrollPos(pos) {
        if (pos > this.stickyHeight) {
            this.stickyHeader = true;
        }
        else {
            this.stickyHeader = false;
        }
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.initializeSubscriptions();
            this.varInit();
            this.products = yield this.storage.get('productsInCart');
            this.products.forEach(product => {
                if (!product.hasOwnProperty('orderType') || (product.hasOwnProperty('orderType') && product.orderType !== 'subscription')) {
                    this.isNoSubscriptionOrder = true;
                }
                if ('extraCharges' in product && product.extraCharges.charge > 0) {
                    const charge = product.extraCharges.chargeAllQty ? product.extraCharges.charge : product.extraCharges.charge / product.quantity;
                    product.price += charge;
                    if ('mrpPrice' in product) {
                        product.mrpPrice += charge;
                    }
                    product.extraCharges.charge = charge * product.quantity;
                }
                this.totalAddonsPrice += ('addOns' in product ? product.addOns.totalPrice : 0);
            });
            this.address = yield this.storage.get('userDefaultAddress');
            const billingAddress = yield this.storage.get('userBillingAddress');
            this.billingAddress = billingAddress || this.address;
            if (this.address && this.billingAddress) {
                if (this.isSame(this.address, this.billingAddress)) {
                    this.isShippingAndBillingSame = true;
                }
                else {
                    this.isShippingAndBillingSame = false;
                }
            }
            //
            /*let storeInfo: any = await this.adminSettingsService.getStoreInfoDataInSessionStorage();
            
            /*if ((Object.keys(storeInfo).length)) {
                console.log('storeInfo', storeInfo)
                this.showCommentBoxAndImage = storeInfo.allowComment || false;
                this.placeholderMsgInCommentBox = storeInfo.commentMsg;
                this.storeAddress = storeInfo.storeAddress || {};
                this.offerMsg = storeInfo.offerMsg || '';
                this.uploadDocSettings = storeInfo.allowImageUpload || this.uploadDocSettings;
                if(this.uploadDocSettings.isActive) {
                    this.uploadedDoc['name'] = this.uploadDocSettings.name;
                }
            }*/
            const storeInfo = yield this.storage.get('storeInfo');
            if (storeInfo && storeInfo !== undefined) {
                this.showCommentBoxAndImage = storeInfo.allowComment || false;
                this.placeholderMsgInCommentBox = storeInfo.commentMsg;
                this.storeAddress = storeInfo.storeAddress || {};
                this.offerMsg = storeInfo.offerMsg || '';
                this.uploadDocSettings = storeInfo.allowImageUpload || this.uploadDocSettings;
                if (this.uploadDocSettings.isActive) {
                    this.uploadedDoc['name'] = this.uploadDocSettings.name;
                }
                this.extraChargeOnOrder.name = 'extraCharge' in storeInfo ? storeInfo.extraCharge.chargeName : '';
                this.customField = 'custom' in storeInfo && storeInfo.custom.active ? storeInfo.custom : { active: false };
            }
            this.userId = yield this.storage.get('uid');
            if (this.userId) {
                this.user = yield this.userService.getUserDetails('return');
                this.customerGstNo = this.user.customerGstNo || '';
            }
            this.events.publish('delivery-settings:getDeliverySettingsData');
            this.events.publish('delivery-settings:getManageSlots');
            this.multiRegion = this.configService.environment.multiRegion;
            if (this.multiRegion) {
                this.events.publish('multi-region:getActiveStatus');
            }
        });
    }
    urlPdtName(pname) {
        return encodeURL(pname);
    }
    varInit() {
        this.currencyCode = this.configService.environment.currencyCode;
        this.taxType = this.configService.environment.taxType;
        this.orderUploadedDocImgsLimit = this.configService.environment.orderUploadedDocImgsLimit;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.ORDER_SUMMARY_LABELS = this.labelService.labels['ORDER_SUMMARY'];
    }
    ionViewWillLeave() {
        this.removeSubscriptions();
    }
    initializeSubscriptions() {
        // this.events.subscribe('order:orderSuccessfullyPlaced', () => {
        //     if (this.sharedService.loading) {
        //         this.sharedService.loading.dismiss();
        //     }
        //     this.orderPlacedAlert();
        // });
        // this.events.subscribe('order:autoConfirmPlaceOrderSuccess', (orderObj) => {
        //     this.presentAutoConfirmPaymentPageModal(orderObj);
        // });
        this.events.subscribe('delivery-settings:publishDeliverySettingsData', (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const customDeliveryOptions = yield this.deliverySettingsService.getCustomDelivery();
            customDeliveryOptions.unshift(Object.assign({ id: 'standard' }, data));
            this.customDeliveryOptions = [];
            customDeliveryOptions.forEach(option => {
                if (this.isCustomDeliveryAvailable(option)) {
                    this.customDeliveryOptions.push(option);
                }
            });
            if (this.customDeliveryOptions.length) {
                this.selectedCustomDeliveryType = this.customDeliveryOptions[0].id;
                this.setDeliveryObj(this.customDeliveryOptions[0]);
            }
            else {
                this.setDeliveryObj(data);
            }
            this.events.publish('admin-settings:getPaymentInfoData');
        }));
        this.events.subscribe('admin-settings:publishPaymentInfoData', (data) => {
            if (!this.isEmptyObj(data)) {
                if (data.minOrderAmount !== '') {
                    this.minOrderAmount = parseInt(data.minOrderAmount);
                }
                if (data.maxOrderAmount !== '') {
                    this.maxOrderAmount = parseInt(data.maxOrderAmount);
                }
                this.autoConfirmOrder = data.autoConfirmOrder;
                this.isGstApplicable = typeof data.isGstApplicable !== undefined ? data.isGstApplicable : true;
                if (this.checkIsInternationalUser()) {
                    this.isGstApplicable = false;
                }
                const additionalInfo = {
                    totalAddonsPrice: this.totalAddonsPrice
                };
                this.events.publish('delivery-settings:getOrderPaymentDetails', this.address, this.products, this.isGstApplicable, this.customDeliveryOptions[0] || null, additionalInfo);
                this.showLoader = false;
                this.showPriceInfoLoader = true;
            }
        });
        this.events.subscribe('delivery-settings:publishOrderPaymentDetails', (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.paymentDetails = response;
            if (!this.isKmDeliveryExist && !this.isWeightDeliveryExist) {
                this.getExtraDeliveryFields();
            }
            this.setDefaultSelectionofDelivery();
            this.initPaymentInfo();
            yield this.getFreeProductLimits();
            this.checkFreeProductStatus();
            this.showPriceInfoLoader = false;
            if (this.loader) {
                this.loader.dismiss();
            }
        }));
        this.events.subscribe('coupon-codes:couponCodeNotApplied', (msg) => {
            this.couponCode = '';
            this.loader.dismiss();
            this.presentAlert(msg);
        });
        this.events.subscribe('coupon-codes:couponCodeApplied', (response) => {
            if (response.details.totalCouponDiscount === 0) {
                this.loader.dismiss();
                this.presentAlert(`${this.ORDER_SUMMARY_LABELS['coupon_not_applicable']}`);
            }
            else {
                let coupon = response.data;
                this.couponApplied = true;
                this.couponId = coupon.couponId;
                this.isCodAvailableForCoupon = 'codAvailable' in coupon ? coupon.codAvailable : true;
                this.gstAmount = response.details.totalGst;
                this.products = response.details.products;
                this.totalAmountToPaid = response.details.totalAmountToPaid;
                this.couponDiscount = response.details.totalCouponDiscount;
                this.totalMrp = 'totalMrp' in response.details ? response.details.totalMrp : this.totalMrp;
                this.discountOnMrp = 'discountOnMrp' in response.details ? response.details.discountOnMrp : this.discountOnMrp;
                let price = 0;
                let totalGst = 0;
                this.products.map((p) => {
                    price += p.price * p.quantity;
                    if (p.gstObj) {
                        totalGst += p.gstObj.total;
                    }
                });
                if (price >= this.freeDeliveryAmt) {
                    this.deliveryFree();
                }
                if (this.deliveryType === 'pickup' && this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
                    this.setStorePickupPayment();
                }
                this.checkUserMembership();
                this.checkFreeProductStatus();
                this.loader.dismiss();
            }
        });
        this.events.subscribe('delivery-settings:publishManageSlots', (slots) => {
            this.manageSlots = slots;
        });
        this.events.subscribe('multi-region:publishActiveStatus', (data) => {
            if (data) {
                this.multiRegion = data.active;
                this.regionType = data.regionType || 'pincodes';
                if (this.multiRegion && this.regionType === 'area') {
                    this.getNearestStoreRegion();
                }
            }
        });
    }
    isEmptyObj(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    presentAutoConfirmPaymentPageModal(orderObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.events.unsubscribe('admin-settings:publishPaymentInfoData');
            const { isCodAvailableForCoupon } = orderObj, order = tslib_1.__rest(orderObj, ["isCodAvailableForCoupon"]);
            const modal = yield this.modalController.create({
                component: AutoConfirmPaymentPage,
                cssClass: 'custom-modal',
                componentProps: {
                    orderData: order,
                    isCodAvailableForCoupon
                }
            });
            return yield modal.present();
        });
    }
    getDeliveryDates(days, lastDeliveryTime) {
        this.showLoader = false;
        this.scheduledDates = [];
        // console.log('maxDaysOfDelivery', this.maxDaysOfDelivery);
        for (let index = 0; index < this.maxDaysOfDelivery; index++) {
            // let dayToCheck = moment().add(index + 1 , 'days');
            let dayToCheck = moment().add(index, 'days');
            // console.log('dayToCheck', dayToCheck);
            if (days.includes(dayToCheck.format('dddd'))) {
                // console.log('days', days)
                this.scheduledDates.push(dayToCheck.toDate());
            }
        }
        console.log('getDeliveryDates scheduledDates', this.scheduledDates);
        if (this.allowSameDayDelivery) {
            this.appendSameDateToDeliveryDates(lastDeliveryTime);
        }
        this.minHrsReqCheck();
    }
    appendSameDateToDeliveryDates(lastTime) {
        let now = moment().format('HH:mm');
        let lastDeliveryTime = moment(lastTime, ['hh:mm A']).format('HH:mm');
        if (now < lastDeliveryTime) {
            if (this.scheduledDates.some(el => el.toDateString() === new Date().toDateString())) {
                return;
            }
            this.scheduledDates.unshift(new Date());
            console.log('appendSameDateToDeliveryDates scheduledDates', this.scheduledDates);
        }
    }
    minHrsReqCheck() {
        const now = parseInt(moment().format('HH'));
        const t1 = now + this.minHrsReq;
        if (t1 > 24) {
            let disabledDays = Math.floor(t1 / 24);
            this.actualTime = t1 % 24;
            let actualDays = [];
            disabledDays = this.allowSameDayDelivery ? disabledDays : disabledDays - 1;
            disabledDays = disabledDays < 0 ? 0 : disabledDays;
            for (let index = disabledDays; index < this.scheduledDates.length; index++) {
                console.log('index', index);
                actualDays.push(this.scheduledDates[index]);
            }
            this.scheduledDates = actualDays;
            console.log('scheduledDates', this.scheduledDates);
            this.needToDisable = true;
        }
        else {
            this.actualTime = t1;
            if (this.allowSameDayDelivery) {
                this.needToDisable = true;
            }
        }
    }
    selectDate(e) {
        this.selectedDate = e.target.value;
        this.selectedTime = null;
    }
    selectTime(e) {
        this.selectedTime = e.target.value;
    }
    disableTime(time) {
        if (this.selectedDate) {
            if (this.needToDisable) {
                const d1 = this.formatDate(this.selectedDate);
                const d2 = this.formatDate(new Date(this.scheduledDates[0]));
                if (d1 === d2) {
                    const startTime = moment(time.start, ['hh:mm A']).format('HH');
                    if (this.actualTime > parseInt(startTime)) {
                        return true;
                    }
                }
            }
            if (this.manageSlots.length > 0) {
                let needToDisable = false;
                this.manageSlots.forEach(manageSlot => {
                    if (this.selectedDate.toDateString() === manageSlot.id) {
                        const slots = manageSlot.slots;
                        slots.forEach(slot => {
                            if ((time.start === slot.start) && (time.end === slot.end)) {
                                if (!slot.active) {
                                    needToDisable = true;
                                }
                                if (slot.orderLimit <= slot.orderCreated) {
                                    needToDisable = true;
                                }
                            }
                        });
                    }
                });
                if (needToDisable) {
                    return true;
                }
            }
        }
    }
    formatDate(d) {
        const day = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        return day + '/' + month + '/' + year;
    }
    // async onClickPlaceOrder() {
    //     if(!this.userId) {
    //         this.sharedService.openLoginModal();
    //         return;
    //     }
    //     if(!this.address) {
    //         this.onClickChangeOrAddAddress('shipping');
    //         return;
    //     }
    //     this.productsPrice = 0;
    //     this.products.map((p) => {
    //         this.productsPrice += ((('pack' in p) && (p.pack.variantType === 'pieces')) ? p.pack.price : p.price) * p.quantity;
    //     });
    //     let uploadDocMandatory = false;
    //     if(this.uploadDocSettings.isActive && this.uploadDocSettings.isMandatory && !this.uploadedDoc.uploads.length) {
    //         uploadDocMandatory = true;
    //     }
    //     if (this.isOrderDeliverable === 'not_deliverable') {
    //         this.presentAlert(this.getNotDeliverableError());
    //     } 
    //     else if (this.productsPrice < this.minOrderAmount) {
    //         this.presentAlert(`${this.ORDER_SUMMARY_LABELS['min_amount_for_placing_order']} ${this.currencyCode} ${this.minOrderAmount}`);
    //     } 
    //     else if (this.maxOrderAmount && (this.productsPrice > this.maxOrderAmount)) {
    //         this.presentAlert(`${this.ORDER_SUMMARY_LABELS['max_amount_for_placing_order']} ${this.currencyCode} ${this.maxOrderAmount}`);
    //     } 
    //     else if (this.deliveryType !== 'pickup' && this.isDeliveryScheduleMandatory && (!this.selectedDate || !this.selectedTime) && this.isNoSubscriptionOrder && !this.isOnlyMembership) {
    //         this.presentAlert(`${this.ORDER_SUMMARY_LABELS['select_delivery_date_time']}`);
    //     } 
    //     else if (this.productsPrice < this.minPincodeAmnt) {
    //         this.presentAlert(`${this.ORDER_SUMMARY_LABELS['min_amount_for_placing_order_on_pincode']} ${this.currencyCode} ${this.minPincodeAmnt}`);
    //     } else if (uploadDocMandatory) {
    //         this.presentAlert(`${this.ORDER_SUMMARY_LABELS['please_upload_doc']} ${this.uploadDocSettings.name}`);
    //     } else if (this.regionNotDeliverable) {
    //         this.presentAlert(this.ORDER_SUMMARY_LABELS['not_delivering_in_your_location_msg']);
    //     } else if (this.issueInGettingLocation) {
    //         this.presentAlert(this.ORDER_SUMMARY_LABELS['issue_in_getting_nearest_store_location']);
    //     } else {
    //         if ((this.totalAmountToPaid - this.defaultDeliveryAmt) >= this.freeDeliveryAmt) {
    //             this.defaultDeliveryAmt = 0;
    //         }
    //         let paymentData = {
    //             productsPrice: this.productsPrice,
    //             delivery: this.defaultDeliveryAmt,
    //             couponDiscount: this.couponDiscount,
    //             defaultGst: this.gstAmount,
    //             totalAmountToPaid: this.totalAmountToPaid,
    //             couponId: this.couponId,
    //             couponName: this.couponCode,
    //             scheduledDate: this.selectedDate ? this.selectedDate : '',
    //             scheduledTime: this.selectedTime ? this.selectedTime : '',
    //             totalMrp: this.totalMrp,
    //             discountOnMrp: this.discountOnMrp,
    //             deliveryGstObj: this.deliveryGstObj,
    //             customerGstNo: this.customerGstNo,
    //             billingAddress: this.billingAddress,
    //             membershipDiscount: this.membershipDiscount,
    //             storePickupObj: {},
    //             uploadedDoc: this.uploadedDoc,
    //             estimatedDeliveryTime: '',
    //             instantDelivery: this.deliveryType === 'instant' ? {selected: true, time: this.instantDelivery.time} : {selected: false},
    //             metaData: { source: 'browser', inventoryManaged: this.autoConfirmOrder ? true : false },
    //             autoConfirmOrder: this.autoConfirmOrder,
    //             freeProductsAdded: this.freeProductsAdded,
    //             extraChargeOnOrder: this.extraChargeOnOrder,
    //             custom: this.customField,
    //             totalAddonsPrice: this.totalAddonsPrice
    //         };
    //         if(this.deliveryType === 'default_delivery' && this.storeDelivery.estimatedTime) {
    //             paymentData.estimatedDeliveryTime = this.storeDelivery.estimatedTime;
    //         }
    //         if(this.deliveryType === 'estimated') {
    //             paymentData.estimatedDeliveryTime = this.estimatedDeliveryTime;
    //         }
    //         if (this.deliveryType === 'pickup' && this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
    //             paymentData['storePickupObj'] = {
    //                 storeAddress: this.storeAddress,
    //                 charges: this.storePickupCharges
    //             };
    //         }
    //         if (!this.autoConfirmOrder) {
    //             await this.sharedService.presentLoading(20000);
    //             const isProductsUpdated = (await this.cartService.compareCartWithUpdatedCart(this.products, 'order-summary')).cartUpdated;
    //             if(isProductsUpdated) {
    //                 this.sharedService.loading.dismiss();
    //                 await this.presentAlert(this.SHARED_LABELS['cart_updated_alert']);
    //                 this.router.navigate(['user-cart']);
    //             } else {
    //                 this.events.publish('order:placeOrder', this.products, this.listOfCommentImages, this.address, paymentData);
    //             }
    //         } else {
    //             this.events.publish('order:autoConfirmPlaceOrder', this.products, this.listOfCommentImages, this.address, paymentData, this.isCodAvailableForCoupon);
    //         }
    //     }
    // }
    onClickChangeOrAddAddress(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: SelectAddressPage,
                cssClass: 'custom-modal',
                componentProps: { type: type }
            });
            modal.onDidDismiss().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.address = yield this.storage.get('userDefaultAddress');
                this.billingAddress = yield this.storage.get('userBillingAddress');
                this.events.publish('delivery-settings:getDeliverySettingsData');
                this.events.publish('delivery-settings:getManageSlots');
                if (this.multiRegion && this.regionType === 'area') {
                    this.getNearestStoreRegion();
                }
            }));
            yield modal.present();
        });
    }
    applyCouponCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.userId) {
                this.sharedService.openLoginModal();
                return;
            }
            this.paymentDetails.products = this.products.filter(p => p.orderType !== 'free');
            yield this.presentLoading(`${this.ORDER_SUMMARY_LABELS['verifying_coupon_code']}`);
            const data = {
                code: this.couponCode,
                paymentDetails: this.paymentDetails,
                isGstApplicable: this.isGstApplicable
            };
            this.events.publish('coupon-codes:verifyCouponCode', data);
        });
    }
    removeCouponCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.couponApplied = false;
            this.couponDiscount = 0;
            this.couponCode = '';
            this.couponId = '';
            this.isCodAvailableForCoupon = true;
            this.initPaymentInfo();
            this.checkFreeProductStatus();
        });
    }
    openCouponsModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.couponApplied) {
                return;
            }
            else {
                const modal = yield this.modalController.create({
                    component: CouponsListPage,
                    cssClass: 'coupons-list-modal modal-bg'
                });
                modal.onDidDismiss().then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res && res.data) {
                        this.couponCode = res.data.couponName;
                        this.applyCouponCode();
                    }
                }));
                yield modal.present();
            }
        });
    }
    scrollToBottom() {
        this.content.scrollToBottom(500);
    }
    orderPlacedAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: this.ORDER_SUMMARY_LABELS['order_placed_alert_header'],
                message: this.ORDER_SUMMARY_LABELS['order_placed_alert_message'],
                backdropDismiss: false,
                buttons: [{
                        text: 'OK',
                        handler: () => {
                            this.navCtrl.navigateRoot(['order-successful']);
                            this.modalController.dismiss();
                        }
                    }]
            });
            yield alert.present();
        });
    }
    presentAlert(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                buttons: this.SHARED_LABELS['ok']
            });
            yield alert.present();
        });
    }
    presentLoading(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingController.create({
                message: msg,
                duration: 30000
            });
            yield this.loader.present();
        });
    }
    textUppercase() {
        this.couponCode = this.couponCode.toUpperCase();
        if (this.couponCode.includes(' ')) {
            this.couponCode = this.couponCode.replace(/\s/g, '');
        }
    }
    changeAddressType(e) {
        this.addressType = e.target.value;
        if (this.addressType === 'billing') {
            if (this.isSame(this.address, this.billingAddress)) {
                this.isShippingAndBillingSame = true;
            }
            else {
                this.isShippingAndBillingSame = false;
            }
        }
    }
    isSame(a, b) {
        if (a.id === b.id) {
            return true;
        }
        else {
            return false;
        }
    }
    changePersonalType(e) {
        this.accountType = e.target.value;
        if (this.accountType === 'personal') {
            this.customerGstNo = '';
        }
    }
    closeModal() {
        this.modalController.dismiss();
    }
    changeDeliveryType(e) {
        this.couponApplied = false;
        this.couponDiscount = 0;
        this.deliveryType = e.target.value;
        if (this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
            if (this.deliveryType === 'pickup') {
                this.setStorePickupPayment();
                this.checkUserMembership();
            }
            else {
                this.selectedDate = null;
                this.selectedTime = null;
                this.initPaymentInfo();
            }
        }
    }
    checkUserMembership() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let membership = { isMember: false, settings: {} };
            let membershipInCart = false;
            if (this.user && this.user.hasOwnProperty('membership') && this.user.membership.isMember) {
                membership.isMember = true;
                membership['settings'] = this.user.membership.planConfig;
            }
            else {
                this.products.forEach(product => {
                    if (product.hasOwnProperty('orderType') && product.orderType === 'membership') {
                        membership.isMember = true;
                        membership['settings'] = product.membershipSettings;
                        membershipInCart = true;
                    }
                });
                if (membership.isMember && this.products.length === 1) {
                    this.isOnlyMembership = true;
                    this.freeDeliveryAmt = 0;
                    this.deliveryFree();
                }
            }
            if (membership.isMember && !this.isOnlyMembership) {
                const settings = membership.settings;
                console.log('settings', settings);
                const totalProductPrice = this.totalMrp - this.discountOnMrp - this.couponDiscount;
                if (settings.isDeliveryFree && (totalProductPrice >= settings.deliveryFreeAmount)) {
                    this.deliveryFree();
                    this.freeDeliveryAmt = settings.deliveryFreeAmount;
                }
                if (settings.discount > 0) {
                    let memberDiscount = 0;
                    if (membershipInCart) {
                        memberDiscount = (totalProductPrice - settings.plan.discountedPrice) * (settings.discount / 100);
                    }
                    else {
                        memberDiscount = totalProductPrice * (settings.discount / 100);
                    }
                    memberDiscount = parseFloat((memberDiscount > settings.maxDiscount ? settings.maxDiscount : memberDiscount).toFixed(2));
                    this.products.map(pdt => {
                        const discountOnPdt = memberDiscount * (pdt.price / (this.totalMrp - this.discountOnMrp));
                        pdt.membershipDiscount = (discountOnPdt > pdt.price) ? pdt.price * pdt.quantity : discountOnPdt * pdt.quantity;
                    });
                    this.membershipDiscount = memberDiscount;
                    this.totalAmountToPaid = (this.totalAmountToPaid - memberDiscount) < 0 ? 0 : this.totalAmountToPaid - memberDiscount;
                }
            }
            console.log('this.membershipDiscount', this.membershipDiscount);
        });
    }
    initPaymentInfo() {
        this.products = this.paymentDetails.products;
        this.defaultDeliveryAmt = this.paymentDetails.delivery.deliveryCost;
        this.isOrderDeliverable = this.paymentDetails.delivery.status;
        this.totalMrp = this.paymentDetails.totalMrp;
        this.discountOnMrp = this.paymentDetails.discountOnMrp;
        this.gstAmount = this.paymentDetails.totalGst;
        this.deliveryGstObj = this.paymentDetails.deliveryGstObj;
        this.totalAmountToPaid = this.paymentDetails.totalPayable;
        this.extraChargeOnOrder.charge = 'extraCharge' in this.paymentDetails ? this.paymentDetails.extraCharge : 0;
        if (this.deliveryType === 'pickup') {
            this.checkIfOnlyStorePickup();
        }
        this.checkUserMembership();
        if ('freeDeliveryAmount' in this.paymentDetails.delivery && this.paymentDetails.delivery.freeDeliveryAmount) {
            this.freeDeliveryAmt = this.paymentDetails.delivery.freeDeliveryAmount;
        }
    }
    deliveryFree() {
        this.defaultDeliveryAmt = 0;
        this.deliveryGstObj = {
            value: 0,
            total: 0
        };
        this.totalAmountToPaid -= this.paymentDetails.delivery.deliveryCost;
    }
    checkIfOnlyStorePickup() {
        if ((!this.isDeliverySchedule || !this.scheduledDates.length) && (this.isStorePickup && this.storeAddress.hasOwnProperty('address'))) {
            this.setStorePickupPayment();
        }
    }
    setStorePickupPayment() {
        this.totalAmountToPaid = parseFloat(((this.paymentDetails.totalPayable - this.paymentDetails.delivery.deliveryCost - this.couponDiscount) + this.storePickupCharges).toFixed(2));
        this.gstAmount += (this.storePickupCharges - (this.storePickupCharges / (1 + ((this.deliveryGstObj.value) / 100)))) - this.paymentDetails.deliveryGstObj.total;
        this.deliveryGstObj.total = (this.storePickupCharges - (this.storePickupCharges / (1 + ((this.deliveryGstObj.value) / 100))));
    }
    uploadDocImage(files) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < files.length; i++) {
                if (this.uploadedDoc.uploads.length < this.orderUploadedDocImgsLimit) {
                    console.log('in if....');
                    const base64Image = yield this.readBase64(files.item(i));
                    this.uploadedDoc.uploads.push({ url: base64Image });
                }
                else {
                    console.log('in else....');
                    break;
                }
            }
        });
    }
    readBase64(file) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                resolve(event.target.result);
            };
        }));
    }
    removeUploadedDocImg(index) {
        this.uploadedDoc.uploads.splice(index, 1);
    }
    imgZoom(img) {
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: [{ url: img }],
                index: 0
            }
        }).then(modal => modal.present());
    }
    isDeliverySchedulesAvailable() {
        return this.isDeliverySchedule && this.scheduledDates.length > 0 ? true : false;
    }
    isStorePickupAvailable() {
        return this.isStorePickup && this.storeAddress.hasOwnProperty('address') ? true : false;
    }
    isDefaultDeliveryAvailable() {
        return this.storeDelivery.isActive ? true : false;
    }
    getExtraDeliveryFields() {
        const data = this.paymentDetails.delivery;
        if ('minAmount' in data) {
            this.minPincodeAmnt = data.minAmount;
        }
        if ('estimatedDeliveryTime' in data) {
            console.log('estimatedDeliveryTime', data.estimatedDeliveryTime);
            if (data.estimatedDeliveryTime) {
                let totalEstimatedHrs = this.getEstimatedTimeInHrs(data.estimatedDeliveryTime);
                const estimatedDate = moment().add(totalEstimatedHrs, 'hours').format('dddd, MMMM DD');
                this.estimatedDeliveryTime = estimatedDate;
                if (this.instantDelivery.isActive) {
                    this.checkInstantDelivery(totalEstimatedHrs);
                }
            }
        }
        if ('freeDeliveryAmount' in data) {
            this.freeDeliveryAmt = data.freeDeliveryAmount;
        }
    }
    getEstimatedTimeInHrs(time) {
        const splitTime = time.split(':');
        const days = +splitTime[0];
        const hrs = +splitTime[1];
        const mins = +splitTime[2];
        let totalHrs = (days * 24) + hrs + (mins / 60);
        return totalHrs;
    }
    showDeliverySection() {
        if ((this.isDeliverySchedulesAvailable() || this.isStorePickupAvailable() || this.isInstantDeliveryAvailable() || this.isEstimatedDeliveryAvailable() || this.isDefaultDeliveryAvailable())
            && this.isNoSubscriptionOrder && !this.isOnlyMembership) {
            return true;
        }
        else {
            return false;
        }
    }
    isInstantDeliveryAvailable() {
        return this.instantDelivery.isAvailable;
    }
    isEstimatedDeliveryAvailable() {
        return this.estimatedDeliveryTime && this.estimatedDeliveryTime !== '' ? true : false;
    }
    setDefaultSelectionofDelivery() {
        if (this.isDeliverySchedulesAvailable()) {
            this.deliveryType = 'delivery';
        }
        else if (this.isInstantDeliveryAvailable()) {
            this.deliveryType = 'instant';
        }
        else if (!this.isDeliverySchedulesAvailable() && this.isEstimatedDeliveryAvailable()) {
            console.log('isEstimatedDeliveryAvailable');
            this.deliveryType = 'estimated';
        }
        else if (!this.isDeliverySchedulesAvailable() && !this.isEstimatedDeliveryAvailable() && this.isDefaultDeliveryAvailable()) {
            this.deliveryType = 'default_delivery';
        }
        else if (this.isStorePickupAvailable()) {
            console.log('isStorePickupAvailable');
            this.deliveryType = 'pickup';
        }
    }
    checkInstantDelivery(estimatedTime) {
        const todayAvailable = moment().isSame(moment(this.scheduledDates[0]));
        if ((this.instantDelivery.time >= estimatedTime) && todayAvailable) {
            this.instantDelivery['isAvailable'] = true;
            this.instantDelivery.time = this.instantDelivery.time <= 1 ? `${this.instantDelivery.time * 60} mins` : `${this.instantDelivery.time} hrs`;
        }
    }
    isMembershipInProducts(orderType) {
        return orderType && orderType === 'membership' ? true : false;
    }
    uploadCommentImgs(files, pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let imgs = this.listOfCommentImages[pid] || [];
            for (let i = 0; i < files.length; i++) {
                const base64Image = yield this.readBase64(files.item(i));
                if (imgs.length !== 0) {
                    imgs.push(base64Image);
                }
                else {
                    imgs = [base64Image];
                }
                this.listOfCommentImages[pid] = imgs;
            }
        });
    }
    removeCommentImage(imgIndex, pid) {
        this.listOfCommentImages[pid].splice(imgIndex, 1);
        console.log('listOfCommentImages', this.listOfCommentImages);
    }
    getNearestStoreRegion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.sharedService.presentLoading(20000, this.ORDER_SUMMARY_LABELS['getting_nearest_store_location']);
            const res = yield this.getRegion();
            if (res.status === 'success') {
                if (res.deliverable) {
                    const region = {
                        id: res.region.id,
                        name: res.region.name,
                        pincode: null,
                        vendorId: res.region.vendorId || null
                    };
                    this.storage.set('region', region);
                    this.sharedService.loading.dismiss();
                }
                else {
                    this.sharedService.loading.dismiss();
                    this.storage.set('region', null);
                    this.regionNotDeliverable = true;
                    this.presentAlert(this.ORDER_SUMMARY_LABELS['not_delivering_in_your_location_msg']);
                }
            }
            else {
                this.sharedService.loading.dismiss();
                this.issueInGettingLocation = true;
                this.presentAlert(this.ORDER_SUMMARY_LABELS['issue_in_getting_nearest_store_location']);
            }
        });
    }
    getRegion() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const nearestRegion = yield this.regionService.getNearestStoreRegion(this.address);
            resolve(nearestRegion);
        }));
    }
    getFreeProductLimits() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const limits = yield this.cartService.getFreeProductLimits();
            if (limits.length) {
                this.freeProductsLimit = limits;
            }
        });
    }
    checkFreeProductStatus() {
        this.freeProductsAdded = [];
        const products = this.products.filter(p => p.orderType !== 'free');
        if (this.freeProductsLimit.length) {
            let totalPrice = this.getTotalPriceWithoutDelivery();
            let limitIndex = -1;
            for (let index = 0; index < this.freeProductsLimit.length; index++) {
                if (totalPrice >= this.freeProductsLimit[index].orderAmount) {
                    limitIndex = index;
                    break;
                }
            }
            if (limitIndex !== -1) {
                this.freeProductsAdded.push(this.freeProductsLimit[limitIndex].id);
                this.freeProductsLimit[limitIndex].freeProduct['gstObj'] = {
                    value: 0,
                    total: 0,
                    cgst: 0,
                    sgst: 0,
                    igst: 0
                };
                this.freeProductsLimit[limitIndex].freeProduct['orderType'] = 'free';
                products.push(this.freeProductsLimit[limitIndex].freeProduct);
                this.products = JSON.parse(JSON.stringify(products));
            }
        }
    }
    moreAmountForFreeProducts() {
        return this.freeProductsLimit[this.freeProductsLimit.length - 1].orderAmount - this.getTotalPriceWithoutDelivery();
    }
    getTotalPriceWithoutDelivery() {
        return this.totalMrp - this.discountOnMrp - this.couponDiscount - this.membershipDiscount;
    }
    setDeliveryObj(data) {
        if (!this.isEmptyObj(data)) {
            if (data.freeDeliveryAmt !== '') {
                this.freeDeliveryAmt = parseInt(data.freeDeliveryAmt);
                console.log('freeDeliveryAmt', this.freeDeliveryAmt);
            }
            this.storeDelivery = 'isStoreDelivery' in data ? data.isStoreDelivery : this.storeDelivery;
            if (this.storeDelivery.isActive && this.storeDelivery.estimatedTime) {
                const hrs = this.getEstimatedTimeInHrs(this.storeDelivery.estimatedTime);
                this.storeDelivery.estimatedTime = moment().add(hrs, 'hours').format('dddd, MMMM DD');
            }
            this.isDeliverySchedule = data.isDeliverySchedule && this.storeDelivery.isActive ? true : false;
            this.isStorePickup = data.isStorePickup;
            if (data.storePickupCharges) {
                this.storePickupCharges = parseInt(data.storePickupCharges);
            }
            this.maxDaysOfDelivery = data.maxDaysOfDelivery;
            if (this.isDeliverySchedule) {
                if (data.allowSameDayDelivery) {
                    this.allowSameDayDelivery = data.allowSameDayDelivery;
                }
                if (data.minHrs) {
                    this.minHrsReq = data.minHrs;
                }
                if (data.deliveryDays.length > 0 && data.timeSchedules.length > 0) {
                    console.log('data.deliveryDays', data.deliveryDays);
                    console.log('this.timeSchedules', this.timeSchedules);
                    console.log('data.lastDeliveryTime', data.lastDeliveryTime);
                    this.getDeliveryDates(data.deliveryDays, data.lastDeliveryTime);
                    this.timeSchedules = data.timeSchedules;
                }
            }
            this.isKmDeliveryExist = data.isKmBasedDelivery && this.storeAddress.lat && this.address.lat;
            this.isWeightDeliveryExist = data.hasOwnProperty('deliveryByWeight') && data.deliveryByWeight.active ? true : false;
            this.instantDelivery = 'instantDelivery' in data ? Object.assign({}, this.instantDelivery, data.instantDelivery) : this.instantDelivery;
            if (typeof data.isDeliveryScheduleMandatory !== 'undefined') {
                this.isDeliveryScheduleMandatory = data.isDeliveryScheduleMandatory;
            }
        }
    }
    changeCustomDeliveryOption(event) {
        const customDeliverySettings = this.customDeliveryOptions.find(c => c.id === event.target.value);
        console.log('customDeliverySettings', customDeliverySettings);
        if (customDeliverySettings && Object.keys(customDeliverySettings).length) {
            this.setDeliveryObj(customDeliverySettings);
            this.events.publish('delivery-settings:getOrderPaymentDetails', this.address, this.products, this.isGstApplicable, customDeliverySettings);
            this.couponApplied = false;
            this.couponDiscount = 0;
            this.couponCode = '';
            this.couponId = '';
            this.showPriceInfoLoader = true;
            this.selectedCustomDeliveryType = customDeliverySettings.id;
        }
    }
    getNotDeliverableError() {
        if (this.selectedCustomDeliveryType) {
            return `${this.selectedCustomDeliveryType} delivery type not available. Please select another option.`;
        }
        else {
            return `${this.ORDER_SUMMARY_LABELS['order_not_deliverable']}`;
        }
    }
    isCustomDeliveryAvailable(data) {
        if ('availableLocations' in data && this.address) {
            if (data.availableLocations.states.some(s => s.stateCode === this.address.stateCode)) {
                return true;
            }
            if (data.availableLocations.pincodes.some(p => p === this.address.pincode)) {
                return true;
            }
            return false;
        }
        else {
            return true;
        }
    }
    checkIsInternationalUser() {
        const adminCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry;
        if (this.address && adminCountry.countryName && adminCountry.countryName.toLowerCase() === 'india' && this.address.country && this.address.country.toLowerCase() !== 'india') {
            return true;
        }
        else {
            return false;
        }
    }
    isAddonAvailable(product) {
        return product.addOns && product.addOns.options && Object.keys(product.addOns.options).length;
    }
    removeSubscriptions() {
        // this.events.unsubscribe('order:orderSuccessfullyPlaced');
        // this.events.unsubscribe('delivery-settings:publishDeliverySettingsData');
        // this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        // this.events.unsubscribe('delivery-settings:publishOrderPaymentDetails');
        // this.events.unsubscribe('coupon-codes:couponCodeNotApplied');
        // this.events.unsubscribe('coupon-codes:couponCodeApplied');
        // this.events.unsubscribe('delivery-settings:publishManageSlots');
        // this.events.unsubscribe('order:autoConfirmPlaceOrderSuccess');
    }
    dateSelect(date) {
        this.selectedDate = date;
        this.selectedTime = null;
        console.log('selectedDate', this.selectedDate);
        this.getTimeCategory(this.selectedDate, this.timeSchedules);
    }
    timeSelect(time) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.disableTime(time)) {
                this.selectedTime = time;
                console.log('not disabled', this.selectedTime);
            }
            else {
                console.log('disabled', this.selectedTime);
            }
        });
    }
    done() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.selectedDate && this.selectedTime) {
                this.modalController.dismiss({ selectedDate: this.selectedDate, selectedTime: this.selectedTime });
            }
            else {
                let desc = "Please select a Date and Time slot";
                const alert = yield this.alertController.create({
                    message: desc,
                    buttons: ['Ok']
                });
                yield alert.present();
            }
        });
    }
    getTimeCategory(selectedDate, timeSchedules) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("getTimeCategory selectedDate", selectedDate);
            console.log("getTimeCategory timeSchedules", timeSchedules);
            // const now: any = moment().format('HH:mm');
            // let slotTime: any = moment(timeSchedules[1].start, ['hh:mm A']).format('HH:mm');
            const slotDate = moment(selectedDate.setHours(23, 59, 59));
            const currentDate = moment(new Date().setHours(0o0, 0o0, 0o0));
            console.log('slotDate', slotDate);
            console.log('currentDate', currentDate);
            const dayDiff = currentDate.diff(slotDate, 'days');
            console.log("dayDiff", dayDiff);
            if (dayDiff == 0) {
                // ? If present time
                console.log("present");
                // const myTime: any = moment(new Date().setHours(0o0, 0o0, 0o0)).format('HH:mm'); // night time
                const myTime = moment().format('HH:mm');
                console.log("myTime", myTime);
                const time12pm = '12:00'; // !! equal to 12:00 pm;
                if (myTime > time12pm) {
                    // ?? if i order after 12pm then don't show slots for this day
                    console.log("in day");
                    for (let item of timeSchedules) {
                        item['show'] = false;
                    }
                }
                else {
                    // ? if i order before 12pm then show slot same day slot of after 12pm
                    // this.scheduledDates.unshift(new Date());
                    console.log("at night");
                    for (let item of timeSchedules) {
                        item['show'] = false;
                        const slotTime = moment(item.start, ['hh:mm A']).format('HH:mm');
                        if (slotTime > time12pm) {
                            // ? after 12pm
                            item['show'] = true;
                            console.log("after 12pm", item);
                        }
                        else {
                            // ? before 12pm
                            item['show'] = false;
                            console.log("before 12pm", item);
                        }
                    }
                }
            }
            else {
                // ? If not present time
                console.log("not present");
                for (let item of timeSchedules) {
                    item['show'] = true;
                }
            }
            // const timeFormat = 'HH:mm:ss';
            // const currentTime = moment(selectedDate, timeFormat);
            // const beforeTime = moment(timeSchedules[0].start, timeFormat);
            // const afterTime = moment(timeSchedules[0].end, timeFormat);
            // // const beforeTime = moment('12:00:00', timeFormat);
            // // const afterTime = moment('23:59:59', timeFormat);
            // const currentTimeF = moment(selectedDate).format(timeFormat);
            // const beforeTimeF = moment(timeSchedules[1].start, ['hh:mm A']).format(timeFormat);
            // const afterTimeF = moment(timeSchedules[1].end, ['hh:mm A']).format(timeFormat);
            // console.log("currentTimeF", currentTimeF);
            // console.log("beforeTimeF", beforeTimeF);
            // console.log("afterTimeF", afterTimeF);
            // console.log("currentTime", currentTime);
            // if (currentTime.isBetween(beforeTime, afterTime)) {
            //     console.log('is between');
            // } else {
            //     console.log('is not between');
            // }
        });
    }
}
